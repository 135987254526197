<template>
  <LiefengContentManage>
    <template v-slot:title>各系统管理</template>
    <template v-slot:toolsbarLeft>
      <div>
        <Button type="info">新增系统</Button>
      </div>
    </template>
    <template v-slot:toolsbarRight>
      <Input
          :maxlength="20"
          placeholder='按名称，编码等关键字搜索'
          v-model.trim="selectName"
          style="width: 200px; margin-right: 5px"
          @on-search="search"
      ></Input>
      <Button type="primary" @click="search">搜索</Button>
      <Button type="success" @click="reset">重置</Button>
    </template>
    <template v-slot:contentArea>
      <div class="container">
        <LiefengTable
            :talbeColumns="tableColumns"
            :tableData="tableData"
            :loading="loading"
            :fixTable="true"
            :curPage="page"
            :total="total"
            :pagesizeOpts="[20, 30, 50, 100]"
            :page-size="pageSize"
            @hadlePageSize="hadlePageSize"
            @tableSelect="tableSelect"
        ></LiefengTable>
      </div>
    </template>
  </LiefengContentManage>
</template>

<script>
//@route('/system')
import LiefengTable from "@/components/LiefengTable";
import LiefengContentManage from "@/views/childmanagement/LiefengContentManage";


export default {
  components: {
    LiefengTable,
    LiefengContentManage,
  },
  data() {
    return {
      tableSelectArr: [], //table选中的
      //table列表
      tableColumns: [
        {
          type: "selection",
          width: 60,
          align: "center"
        },
        {
          title: '系统代码',
          key: 'name',
          minWidth: 200,
          align: "center",
        },
        {
          title: '系统名称',
          key: 'code',
          align: "center",
          minWidth: 100
        },
        {
          title: '系统地址',
          key: 'province',
          align: "center",
          minWidth: 200
        },
        {
          title: '系统logo',
          key: 'time',
          minWidth: 250,
          align: "center",
        },
        {
          title: '系统形象图片',
          key: 'address',
          minWidth: 250,
          align: "center",
        },
        {
          title: '授权菜单数',
          key: 'status',
          minWidth: 100,
          align: "center",
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          align: "center",
          minWidth: 300,
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                }
              }, '菜单管理'),
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                }
              }, '系统调度设置'),
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                }
              }, '修改'),
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                }
              }, '删除'),
            ]);
          }
        }
      ],
      tableData: [], //table的数据
      loading: false, //table 加载
      page: 1, //分页
      total: 0,   //总数量
      pageSize: 20, //每一页显示的数量
      selectName: "", // 搜索名称或编码
    };
  },
  beforeMount() {
    this.tableHeight = window.innerHeight - 200 + '';
  },
  methods: {
    tableSelect(data) { //表格选中
      this.tableSelectArr = JSON.parse(JSON.stringify(data));
      console.log(data)
    },
    // hadlePageSize(obj) {
    //   this.loading = true;
    //   this.$get("/syshequ/api/sytop/pc/org/selectRegionTreePage", {
    //     communityCode: this.communityCode,  //社区编码
    //     oemCode: parent.vue.oemInfo.oemCode,
    //     name: this.selectName,
    //     roadCode: this.areaObj.orgCode || '',  //街巷编码
    //     parentId: this.areaObj.regionId ? this.areaObj.regionId : !this.areaObj.regionType ? 0 : this.areaObj.id,
    //     page: obj.page,
    //     pageSize: obj.pageSize,
    //   }).then(res => {
    //     this.loading = false;
    //     if (res.code == 200 && res.dataList) {
    //
    //
    //           this.tableData = res.dataList;
    //           this.page = res.currentPage;
    //           this.pageSize = res.pageSize;
    //           this.total = res.maxCount;
    //         } else {
    //           this.$Message.error({
    //             background: true,
    //             content: res.desc
    //           });
    //         }
    //         })
    //         .catch(err => {
    //         this.loading = false;
    //         this.$Message.error({
    //           background: true,
    //           content: "获取数据失败"
    //         });
    //       });
    //
    // },
    //搜索
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize
      });
    },
    //重置
    reset() {
      this.selectName = "";
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      });
    },
  },
};
</script>

<style scoped lang='less'>
.container {
  height: calc(100vh - 85px);
  width: calc(100vw - 20px);
}

</style>

